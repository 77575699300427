<template>
  <div>

    <form-wizard
      ref="wizard"
      color="#000"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Finalizar"
      next-button-text="Próximo"
      back-button-text="Voltar"
      class="mb-3"
      @on-complete="formSubmitted"
      @on-change="alterandoTab"
    >


      <!-- informações do hit -->
      <tab-content
        title="Informações do Hit"
        :before-change="validationHitInformacoes"
      >
        <validation-observer
          ref="hitInformacoesRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Informações do Hit
              </h5>
              <small class="text-muted">
                Insira as informações
              </small>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nome do Hit"
                label-for="hitNome"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitNome"
                  rules="required"
                >
                  <b-form-input
                    id="hitNome"
                    v-model="hitNome"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Digite o nome do seu hit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Compositores"
                label-for="hitCompositores"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitCompositores"
                  rules="required"
                >
                  <b-form-input
                    id="hitCompositores"
                    v-model="hitCompositores"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Digite o(s) nome(s) do(s) compositore(s)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Pretensão - Valor de Liberação"
                label-for="hitValorLiberacao"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitValorLiberacao"
                  vid="hitValorLiberacao"
                >
                  <money
                    id="hitValorLiberacao"
                    v-model="hitValorLiberacao"
                    v-bind="money"
                    class="form-control lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Pretensão - Valor de Exclusividade"
                label-for="hitValorExclusividade"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Valor de Exclusividade"
                >

                  <money
                    id="hitValorExclusividade"
                    v-model="hitValorExclusividade"
                    v-bind="money"
                    class="form-control lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- temas -->
      <tab-content
        title="Tema"
        :before-change="validationHitTema"
      >
        <validation-observer
          ref="hitTemaRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Tema
              </h5>
              <small class="text-muted">Selecione um ou mais temas.</small>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="hitTema"
              >
                <validation-provider
                  #default="{ errors }"
                  name="HitTema"
                >
                  <b-form-checkbox-group
                    id="hitTemas"
                    v-model="hitTemas"
                    class="row col-12"
                    :options="temas"
                    name="hitTemas"
                    buttons
                    button-variant="outline-dark col-6 col-md-3 mb-1"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </validation-observer>
      </tab-content>

      <!-- estilo -->
      <tab-content
        title="Estilo"
        :before-change="validationHitEstilo"
      >
        <validation-observer
          ref="hitEstiloRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Estilo
              </h5>
              <small class="text-muted">Selecione um ou mais estilos.</small>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="hitEstilo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="HitEstilo"
                >
                  <b-form-checkbox-group
                    id="hitEstilos"
                    v-model="hitEstilos"
                    class="row col-12"
                    :options="estilos"
                    name="hitEstilos"
                    buttons
                    button-variant="outline-dark col-6 col-md-3 mb-1"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </validation-observer>
      </tab-content>

      <!-- letra  -->
      <tab-content
        title="Letra"
        :before-change="validationHitLetra"
      >
        <validation-observer
          ref="hitLetraRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Letra
              </h5>
              <small class="text-muted">Preencha a letra da música.</small>
            </b-col>

            <b-col md="12">
              <b-form-group
                label-for="hitLetra"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitLetra"
                  rules="required"
                >
                  <b-form-textarea
                    id="hitLetra"
                    v-model="hitLetra"
                    placeholder="Letra da música"
                    rows="8"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </validation-observer>
      </tab-content>

      <!-- guia -->
      <tab-content
        title="Guia"
        :before-change="validationHitGuia"
      >
        <validation-observer
          ref="hitGuiaRules"
          tag="form"
        >
          <b-row>

            <b-col
              xl="6"
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Guia (Arquivo)
              </h5>
              <small class="text-muted">Carregue o arquivo da sua guia.</small><br><br>
              <span class="mt-1">
                - Tamanho máximo do arquivo: <b>20mb</b><br>
                - Formatos aceitos: <b>.mp3, .wav, .ogg</b><br>
                - A voz da guia precisa estar com uma boa qualidade<br>
              </span>
            </b-col>

            <b-col
              xl="6"
              cols="12"
            >
              <b-form-group
                label="Arquivo"
                label-for="hitGuia"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitGuia"
                  rules="required"
                >
                  <b-form-file
                    id="hitGuia"
                    v-model="hitGuia"
                    :state="Boolean(hitGuia)"
                    accept="audio/mp3"
                    placeholder="Faça o upload do arquivo"
                    @change="teste($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>

        </validation-observer>
      </tab-content>

      <template
        slot="footer"
        slot-scope="props"
      >
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0 && !props.isLastStep"
            :style="props.fillButtonStyle"
            @click.native="props.prevTab()"
          >
            Voltar
          </wizard-button>
        </div>
        <div class="wizard-footer-right">
          <wizard-button
            v-if="!props.isLastStep"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            @click.native="props.nextTab()"
          >
            Próximo
          </wizard-button>

          <wizard-button
            v-else
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            @click.native="props.nextTab()"
          >
            {{ props.isLastStep ? 'Finalizar' : 'Próximo' }}
          </wizard-button>
        </div>
      </template>

    </form-wizard>

    <!-- basic modal -->
    <b-modal
      id="hitCarregando"
      ref="hitCarregando"
      centered
      title="Enviando Hit"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-card-text class="text-center p-2">
        <strong>Seu hit está sendo carregado.</strong><br> Não feche e não atualize essa página.<br>
        <b-spinner
          class="mt-2"
          variant="primary"
        />
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import router from '@/router'
import {
  FormWizard, TabContent, WizardStep, WizardButton,
} from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormFile,
  BModal,
  VBModal,
  BButton,
  BCardText,
  BSpinner,
  BListGroup, 
  BListGroupItem,
} from 'bootstrap-vue'
import { required, email } from '@validations'

import useJwt from '@/auth/jwt/useJwt'

import { Money } from 'v-money'

import axios from 'axios'

import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      hitNome: {
        required: 'O nome do hit é obrigatório.',
      },
      hitCompositores: {
        required: 'O(s) nome(s) do(s) compositor(es) é/são obrigatório(s).',
      },
      hitLetra: {
        required: 'A letra do hit é obrigatória.',
      },
      hitGuia: {
        required: 'O upload do arquivo da guia é obrigatório.',
      },
    },
  },
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    WizardButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    vSelect,
    BFormInvalidFeedback,
    BFormCheckboxGroup,
    BModal,
    BButton,
    BCardText,
    BSpinner,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Money,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    hit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      hitNome: '',
      hitCompositores: '',
      hitValorLiberacao: '',
      hitValorExclusividade: '',
      hitTemas: [], // Temas selecionados
      temas: [],
      hitEstilos: [], // Estilos selecionados
      estilos: [],
      hitLetra: '',
      hitGuia: [],
      hitArquivo: '',
      hitId: '',
      jwtConfig: { ...jwtDefaultConfig },
      carregamentoApi: false,
      statusAprovacao: null,
      tabInicio: 0,
    }
  },
  created() {
    // PUXAR TEMAS
    this.hitTemasLista()

    // PUXAR ESTILOS
    this.hitEstilosLista()

    // PUXAR DADOS DO HIT
    this.hitCompositorId()
    
  },
  methods: {

    hitCompositorId() {

        const hit = this.hit

        this.hitNome = hit.nomeComposicao
        this.hitCompositores = hit.nomeCompositores
        this.hitValorLiberacao = hit.valorLiberacao
        this.hitValorExclusividade = hit.valorExclusividade
        this.hitTemas = hit.listaTemas.map(tema => tema.id)
        this.hitEstilos = hit.listaEstilos.map(estilo => estilo.id)
        this.hitLetra = hit.letra
        this.hitId = hit.id
        this.statusAprovacao = hit.statusAprovacaoHit
    },

    alterandoTab: function (activeStep, nextStep) {

      const wizardNav = document.querySelector('.wizard-nav'); // selecione o elemento ul que contém as guias
      const numSteps = wizardNav.querySelectorAll('li').length; // conte o número de elementos li dentro do ul

      const guiaAtual = nextStep + 1

      if (numSteps == guiaAtual) {

        const navList = document.querySelectorAll('.wizard-nav-pills li');

        navList.forEach((li, index) => {
          if (index !== navList.length - 1) {
            li.style.display = 'none';
          } else {
            const a = li.querySelector('a');
            if (a) {
              const style = document.createElement('style');
              style.innerHTML = `a::before { display: none; }`;
              document.head.appendChild(style);
            }
          }
        });
        
      }
      
    },

    teste(event) {
      const file = event.target.files[0]

      const formData = new FormData()

      formData.append('file', file)

      // console.log( formData );return false;
    },

    hitTemasLista() {
      useJwt.hitTemasLista({
        page: 0,
        size: 9999,
        sort: 'ASC',
      })
        .then(response => {
        // console.log( response.data );

          response.data.content.forEach(tema => {
          // this.temas.push({text: tema.nome, value: {id: tema.id, nome: tema.nome} }); // ENVIANDO ID E NOME
            this.temas.push({ text: tema.nome, value: tema.id })
          })

          // this.temas = response.data;
        })
        .catch(error => {
          console.log(error)
        })
    },

    hitEstilosLista() {
      useJwt.hitEstilosLista({
        page: 0,
        size: 9999,
        sort: 'ASC',
      })
        .then(response => {
        // console.log( response.data );

          response.data.content.forEach(estilo => {
          // this.estilos.push({text: estilo.nome, value: {id: estilo.id, nome: estilo.nome} }); // ENVIANDO ID E NOME
            this.estilos.push({ text: estilo.nome, value: estilo.id })
          })

          // this.temas = response.data;
        })
        .catch(error => {
          console.log(error)
        })
    },

    formSubmitted() {
      // console.log( this.hitGuia.name );

      // MODAL - HIT CARREGANDO - ABRIR
      this.$refs.hitCarregando.show()

      // console.log( this.hitTemas );

      // console.log( this.hitGuia );

      const formData = new FormData()
      formData.append('arquivo', this.hitGuia)

      // console.log( formData );return false;

      // API - CADASTRAR HIT

      axios.post(`${this.jwtConfig.hitCompositorEditarUploadEndpoint}/${this.hitId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          // console.log( response );return false;

          this.$swal({
            title: 'Hit Enviado!',
            text: 'Seu hit está em análise, assim que for aprovado você receberá uma notificação, e já estará pronto para ser negociado.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'hit-lista' })
            } else {
              this.$router.push({ name: 'hit-lista' })
            }
            // console.log( result );
          })
        })
        .catch(error => {
          // console.log( error.response.data.message );

          this.$swal({
            title: 'Ops! Aconteceu um erro.',
            text: error.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .finally(response => {
          // MODAL - HIT CARREGANDO - FECHAR
          this.$refs.hitCarregando.hide()

          console.log(response); return false
        })

    },
    validationHitInformacoes() {

      return new Promise((resolve, reject) => {
        this.$refs.hitInformacoesRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationHitTema() {
      return new Promise((resolve, reject) => {
        this.$refs.hitTemaRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationHitEstilo() {
      return new Promise((resolve, reject) => {
        this.$refs.hitEstiloRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationHitLetra() {
      return new Promise((resolve, reject) => {
        this.$refs.hitLetraRules.validate().then(success => {
          if (success) {
            // API - CADASTRAR HIT

            useJwt.hitCompositorEditar({
              id: this.hitId,
              nomeCompositores: this.hitCompositores,
              nomeComposicao: this.hitNome,
              valorLiberacao: Number(this.hitValorLiberacao),
              valorExclusividade: Number(this.hitValorExclusividade),
              letra: this.hitLetra,
              listaTemas: this.hitTemas,
              listaEstilos: this.hitEstilos,
            })
              .then(response => {

                
                this.$swal({
                  title: 'Informações Editadas!',
                  text: 'Agora você precisa fazer o upload do arquivo da guia para finalizar a edição do hit.',
                  icon: 'success',
                  confirmButtonText: 'Fazer Upload da Guia',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.isConfirmed) {
                    // PASSA PARA PRÓXIMA GUIA
                    resolve(true)
                  } else {
                    // PASSA PARA PRÓXIMA GUIA
                    resolve(true)
                  }
                })
              })
              .catch(error => {

                this.$swal({
                  title: 'Ops! Aconteceu um erro.',
                  text: error.response.data.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .finally(() => {

               
              })

            // API - CADASTRAR HIT
          } else {
            reject()
          }
        })
      })
    },
    validationHitGuia() {
      return new Promise((resolve, reject) => {
        this.$refs.hitGuiaRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Procurar';
}

[dir=ltr] .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
  border-right-width: 1px !important;
}

[dir] .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  box-shadow: none;
}

@media (max-width: 767px) {
  ul.wizard-nav.wizard-nav-pills li {
    display: none !important;
  }
  
  ul.wizard-nav.wizard-nav-pills li.active {
    display: block !important;
  }
}


</style>
