<template>
  <div>

    <b-alert
      v-if="statusAprovacao === 'AGUARDANDO_GUIA' && !carregamentoApi"
      variant="warning"
      show
    >
      <div class="alert-body">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
        <span><b>Seu hit está em rascunho:</b></span><br>
        <span>Estamos aguardando a sua guia para finalizar o envio do seu hit</span>
      </div>
    </b-alert>

    <b-alert
      v-else-if="!carregamentoApi"
      variant="danger"
      show
    >
      <div class="alert-body">
        <feather-icon
          icon="AlertTriangleIcon"
          class="mr-50"
        />
        <span><b>Seu hit foi reprovado.</b></span><br>
        <span>{{ motivoStatus }}. Edite e reenvie.</span>
      </div>
    </b-alert>
    

    <hit-editar-reprovado-geral v-if="statusAprovacao === 'REPROVADO_GERAL'" :hit="hit"></hit-editar-reprovado-geral>
    <hit-editar-reprovado-informacoes v-if="statusAprovacao === 'REPROVADO_INFORMACOES'" :hit="hit"></hit-editar-reprovado-informacoes>
    <hit-editar-reprovado-guia v-if="statusAprovacao === 'REPROVADO_GUIA' || statusAprovacao === 'AGUARDANDO_GUIA'" :hit="hit"></hit-editar-reprovado-guia>

  </div>
</template>

<script>
import router from '@/router'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormFile,
  BModal,
  VBModal,
  BButton,
  BCardText,
  BSpinner,
  BListGroup, 
  BListGroupItem,
  BAlert,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'

import axios from 'axios'

import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

import HitEditarReprovadoGeral from './HitCompositorEditarReprovadoGeral.vue'
import HitEditarReprovadoInformacoes from './HitCompositorEditarReprovadoInformacoes.vue'
import HitEditarReprovadoGuia from './HitCompositorEditarReprovadoGuia.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BFormInvalidFeedback,
    BFormCheckboxGroup,
    BModal,
    BButton,
    BCardText,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components

    HitEditarReprovadoGeral,
    HitEditarReprovadoInformacoes,
    HitEditarReprovadoGuia,

  },
  data() {
    return {
      jwtConfig: { ...jwtDefaultConfig },
      carregamentoApi: false,
      hit: [],
      statusAprovacao: null,
      motivoStatus: null,
    }
  },
  created() {
  
    // PUXAR DADOS DO HIT
    this.hitCompositorId()
    
  },
  methods: {

    hitCompositorId() {
      this.carregamentoApi = true

      useJwt.hitCompositorId({
        id: router.currentRoute.params.id,
      })
        .then(response => {
          //console.log(response.data)
          this.hit = response.data
          // this.hitNome = response.data.nomeComposicao
          // this.hitCompositores = response.data.nomeCompositores
          // this.hitValorLiberacao = response.data.valorLiberacao
          // this.hitValorExclusividade = response.data.valorExclusividade
          // this.hitTemas = response.data.listaTemas.map(tema => tema.id)
          // this.hitEstilos = response.data.listaEstilos.map(estilo => estilo.id)
          // this.hitLetra = response.data.letra
          // this.hitId = response.data.id
          this.motivoStatus = response.data.motivoStatus
          this.statusAprovacao = response.data.statusAprovacaoHit

        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },
    
  }

}
</script>
